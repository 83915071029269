import React from 'react';
import { graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import MainLayout from 'src/layout/MainLayout';
import {
  Grid,
  Paragraphs,
  SectionHead,
  ContentBox,
  NonStretchedImage,
  HalvedContent
} from 'src/components';
import { Card, CardHeader } from 'src/components/Cards';
import content from 'src/content/unsere-dienstleistungen/bav-beratung-und-betreuung/betriebsrenten-portal';
import subpageNavigations from 'src/data/subpage-navigations';

const BetriebsrentenPortalPage = ({ data }) => (
  <MainLayout
    title={content.title}
    headvisual={{
      image: data.headvisual,
      headline: content.headvisualHeadline,
      position: 'right'
    }}
    subpageNavItems={subpageNavigations.beratungUndBetreuung}
    activeNavItem={2}
    useParentPathname
  >
    <section>
      <Grid>
        <HalvedContent swapped breakpoint="xl">
          <BackgroundImage
            fluid={data.twomenincoffeeshop.childImageSharp.fluid}
          />
          <ContentBox
            background="gray-blue"
            topline={content.intro.topline}
            headline={content.intro.headline}
            align="right"
          />
        </HalvedContent>
        <div className="children-margin">
          <Paragraphs items={content.intro.paragraphs} />
        </div>
      </Grid>
    </section>

    <section>
      <SectionHead headline={content.stuetze.headline} centered>
        <p>{content.stuetze.paragraph}</p>
      </SectionHead>

      <NonStretchedImage
        className="container container--simple children-margin"
        fluid={data.illustration.childImageSharp.fluid}
      />

      <Grid>
        {content.stuetze.cards.map((card, index) => (
          <Card key={index}>
            <CardHeader headline={card.headline}>
              <span className="inline-flex justify-center items-center w-16 h-16 font-bold text-3xl bg-dark-blue text-white rounded-full mb-5">
                {index + 1}.
              </span>
            </CardHeader>
            <p>{card.paragraph}</p>
          </Card>
        ))}
      </Grid>
      <ContentBox
        background="light-salmon"
        headline={content.portal.headline}
        single
      >
        <p className="text-2xl">{content.portal.paragraph}</p>

        <span className="text-2xl">
          {content.portal.contactText}{' '}
          <a className="font-bold" href={`mailto:${content.portal.emailLink}`}>
            {content.portal.emailLink}
          </a>
        </span>
      </ContentBox>
    </section>
  </MainLayout>
);

export default BetriebsrentenPortalPage;

export const query = graphql`
  {
    headvisual: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "man-with-one-other-man-in-office.jpg" }
    ) {
      ...image
    }
    twomenincoffeeshop: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "man-sit-in-coffee-shop.jpg" }
    ) {
      ...image
    }
    illustration: file(
      sourceInstanceName: { eq: "illustrations" }
      relativePath: { eq: "bike.png" }
    ) {
      ...image
    }
  }
`;
