const content = {
  title: 'bAV-Portale - Betriebsrenten-Portal',
  headvisualHeadline: 'bAV-Portale - Betriebsrenten-Portal',
  intro: {
    topline: 'Betriebsrenten-Portal',
    headline: 'bAV ist wie Fahrrad fahren: Aller Anfang ist schwer',
    paragraphs: [
      'In Zeiten des demografischen Wandels und Fachkräftemangels stellen Sie sich eine Frage:  Wie kann ich mich als attraktiver Arbeitgeber präsentieren?',
      'Indem Sie Ihre Mitarbeiter bei dem Aufbau einer zusätzlichen Altersversorgung unterstützen. Doch viele empfinden das Thema als schwierig und wenig transparent. Einfach aufsteigen und losradeln – so einfach ist es leider nicht. Die Folge: Sie als Unternehmer und Ihre Belegschaft lassen beste Chancen ungenutzt. Das muss nicht sein!',
      'Mit dem NÜRNBERGER Betriebsrenten-Portal machen Sie Ihre Mitarbeiter fit: Hier können Sie sich umfassend über die Möglichkeiten der bAV in ihrer Firma informieren. Und sich auch gleich ein Angebot erstellen lassen.'
    ]
  },
  stuetze: {
    headline: 'Ihre Stützräder',
    paragraph:
      'Mit dem NÜRNBERGER Betriebsrenten-Portal wird bAV so einfach wie Fahrrad fahren. Für Sie und Ihre Mitarbeiter.',
    cards: [
      {
        headline: 'Informieren',
        paragraph:
          'bAV auf einen Blick und verständlich. Im Portal finden Ihre Mitarbeiter alle wichtigen Informationen: klar strukturiert und mit vielen Grafiken veranschaulicht.'
      },
      {
        headline: 'Beantragen',
        paragraph:
          'Keine bürokratischen Hürden. Das Angebot (Umwandlungsvereinbarung) einfach ausdrucken und dem Vermittler übergeben.'
      },
      {
        headline: 'Ausrechnen',
        paragraph:
          'Welche Rente mit welchem Beitrag? Mit wenigen Angaben erfährt Ihr Mitarbeiter, mit welchem Betrag vom Nettogehalt er welche Betriebsrente bekommt.'
      }
    ]
  },
  portal: {
    headline: 'Unser Portal erledigt das für Sie.',
    paragraph:
      'Online, einfach und individuell – unkomplizierter geht‘s nicht! Und das Beste: Alle Beteiligten sparen Zeit und Mühe.',
    contactText: 'Kontaktieren Sie uns gerne per E-Mail an',
    emailLink: 'bav-portal@nuernberger.de'
  }
};

export default content;
